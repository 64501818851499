import { Component, OnInit } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { FileUpload } from 'src/app/shared/model/fileupload';
import { DataService } from 'src/app/shared/service/data.service';
import { UploadFileService } from 'src/app/shared/service/upload.service';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  submit;item={};
  modalReference;

  message;index; 
  selectedFiles:any;
  currentFileUpload: any; 
  percentage: number = 0;
  itemImage={id: '',name:''};
  startupload = false;

  constructor(
    public dataService: DataService,
    private uploadService: UploadFileService
    ) { }

  ngOnInit() {
  }

  addx(){
    this.submit = false;
    this.item = {date: new Date()};
    this.itemImage = {id:'',name:''};
    
    $('#additem').modal({backdrop: 'static', keyboard: false}) 
  }

  save(){
    this.submit = true;

    if (!this.item['id'])
    this.item['id'] = UUID.UUID();

    if (!this.item['type'] || !this.item['band'] || !this.item['email'] || !this.validateEmail(this.item['email']) || !this.item['image']){
      return;
    }

    this.dataService.saveItem("items", this.item).then(data => {
      delete this.item;
      $('#additem').modal('hide');
    })
  }

  feedback(){
    this.submit = false;
    this.item = {date: new Date()};
    
    $('#addobs').modal({backdrop: 'static', keyboard: false}) 
  }

  saveobs(){
    this.submit = true;

    if (!this.item['id'])
    this.item['id'] = UUID.UUID();

    if (!this.item['email'] || !this.validateEmail(this.item['email']) || !this.item['obs']){
      return;
    }

    this.dataService.saveItem("feedback", this.item).then(data => {
      delete this.item;
      $('#addobs').modal('hide');
    })
  }

  onClickImg(id) {
    document.getElementById(id).click();
  }
  selectFile(event, id, index) {  
    this.selectedFiles = event.target.files;
    //this.preview(this.selectedFiles);
    this.compress(id, index, this.selectedFiles[0]);
  }
  compress(id, index, file) {
    let image: File = file;
    console.log(`Image size before compressed: ${image.size} bytes.`)

    this.selectedFiles = undefined;

    this.itemImage = { id: UUID.UUID(), name: file.name };

    let ext = 'jpg';
    if (file.name.split('.').length > 0)
      ext = file.name.split('.')[file.name.split('.').length - 1];
      
    this.currentFileUpload = new FileUpload(file);
    this.index = index;
    this.percentage = 10;

    // this.compressImage.compress(image)
    //   .pipe(take(1))
    //   .subscribe(compressedImage => {
    //     console.log(`Image size after compressed: ${compressedImage.size} bytes.`);
    //     this.upload(id, index, compressedImage)
    //     // now you can do upload the compressed image 
    //   })

    this.upload(id, index, image);
  }
  upload(id, index, image) {
  
      let self = this;
      this.index = index;

      if (!image)
      return;

      const file = image;

      this.startupload = true;
      
      this.selectedFiles = undefined;
      delete this.selectedFiles;    
      (<HTMLInputElement>document.getElementById(id)).value = '';

      this.itemImage = {id: UUID.UUID(), name: file.name};

      let ext = 'jpg';
      if (file.name.split('.').length > 0)
      ext = file.name.split('.')[file.name.split('.').length -1];      

      this.message = "waiting ..."
      this.currentFileUpload = new FileUpload(file);

      this.uploadService.pushFileToStorage(this.currentFileUpload, this.itemImage, 'images', ext).subscribe(
        async percentage => {
          this.percentage = Math.round(percentage && percentage > 10?percentage:10);
  
          if (this.percentage == 100) {

            let nr = 3000;
            while (!self.itemImage['file_url'] && nr >= 0) {
                await new Promise(resolve => setTimeout(() => resolve(true), 100));
                nr = nr - 10;
            }

            delete self.message;
            this.currentFileUpload = undefined;  

            this.item['image'] = self.itemImage['file_url'];  

            this.percentage = 0;
          }
        },
        error => {
          console.log(error);
        }
      );
    }

    clickfile(){
      if(document.getElementById('uploadControl1'))
      document.getElementById('uploadControl1').click()
    }

    validateEmail(email) {
      let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
      return regex.test(email);
    }
}
