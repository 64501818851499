import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSiteRoutingModule } from './user-site-routing.module';
import {HomeComponent} from "./pages/home/home.component";
import { ItemDetailsComponent } from './pages/item-details/item-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module'

@NgModule({
  declarations: [HomeComponent, ItemDetailsComponent],
  imports: [
    CommonModule,
    UserSiteRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    PipesModule
  ]
})
export class UserSiteModule { }
