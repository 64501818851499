import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../shared/service/data.service";
import {ActivatedRoute} from "@angular/router";
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit {

  email;submit;msg;
  ItemList: any = [];

  constructor(private dataService: DataService,
              private route : ActivatedRoute) { }

  ngOnInit() {
    this.loadItem();
  }

  loadItem() {
    const id = this.route.snapshot.params['id'];

    return this.dataService.getByID("items",id).subscribe((data: {}) => {
      this.ItemList = data;
    });
  }

  calculateDiff(dateSent){
    let currentDate = new Date();
    dateSent = new Date(dateSent);

    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24));
  }

  send(){
    if (!this.ItemList.image){
      this.msg = "Nu este fisier atasat!";
    }

    this.submit= true;

    if (!this.email || !this.validateEmail(this.email))
    return;

    this.msg = "Trimite mail ...";

    this.dataService.saveItem("items", {id:this.ItemList.id, download: true}).then(data => {
      let subject = 'Invitatie/voucher - ati primit de la BerariaH.club';

      let body = `Buna ziua, <br><br>

      Ati primit invitatie/voucher de la BerariaH.club. <br>
      Folositi link-u <a href="`+this.ItemList.image+`">click aici</a>  <br><br>

      Cu drag, <br>
      Echipa BerariaH.club <br>
      https://BerariaH.club`;

      this.dataService.saveItem("email", {id: UUID.UUID(), to: this.email, subject: subject, body: body}).then(data => {

        this.msg = "Trimis mail cu succes la adresa "+this.email;
        delete this.submit;
        delete this.email;

        setTimeout(() => {
          delete this.msg;
        }, 5000);
      })
    })
  }

  validateEmail(email) {
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    return regex.test(email);
  }
}
