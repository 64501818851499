import { Component, OnInit } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { FileUpload } from 'src/app/shared/model/fileupload';
import {DataService} from "../../../shared/service/data.service";
import { UploadFileService } from 'src/app/shared/service/upload.service';
import {environment} from "src/environments/environment";
declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  submit;item = {};
  list: any = [];
  term;newsletter;msg;
  listnewsletter=[]; listemail=[]; listitems=[];

  listsend='';

  environment = environment;
  modalReference;

  message;index; 
  selectedFiles:any;
  currentFileUpload: any; 
  percentage: number = 0;
  itemImage={id: '',name:''};
  startupload = false;

  constructor(
    private dataService: DataService,
    private uploadService: UploadFileService
  ) {

  }

  ngOnInit() {
    this.dataService.getList("items").subscribe((data) => {
      this.list = data.filter(function (entry) {
        return !entry['download']
      });
    })
    this.dataService.getList("newsletter").subscribe((data1) => {
      this.listnewsletter = data1;

      this.dataService.getList("email").subscribe((data2) => {
        this.listemail = data2;

        this.dataService.getList("items").subscribe((data3) => {
          this.listitems = data3;

          data1.forEach(element1 => {
            if (element1['email'] && this.listsend.indexOf(element1['email']) < 0)
              this.listsend = this.listsend + element1['email'] + ','
          });
  
          data2.forEach(element2 => {
            if (element2['to'] && this.listsend.indexOf(element2['to']) < 0)
              this.listsend = this.listsend + element2['to'] + ','
          });

          data3.forEach(element3 => {
            if (element3['email'] && this.listsend.indexOf(element3['email']) < 0)
              this.listsend = this.listsend + element3['email'] + ','
          });

          console.log('---',this.listsend);

        })

      })
    })
  }

  add(){
    this.submit = false;
    this.item = {date: new Date()};
    this.itemImage = {id:'',name:''};
    
    $('#additem').modal({backdrop: 'static', keyboard: false}) 
  }

  save(){
    this.submit = true;

    if (!this.item['id'])
    this.item['id'] = UUID.UUID();

    if (!this.item['type'] || !this.item['band'] || !this.item['email'] || !this.validateEmail(this.item['email']) || !this.item['image']){
      return;
    }

    this.dataService.saveItem("items", this.item).then(data => {
      delete this.item;
      $('#additem').modal('hide');
    })
  }

  getlist(){
    let self = this;
    let result = [];

    if (!this.term)
    result = this.list;
    else
    result = this.list.filter(function (entry) {
      return (entry['band'] && entry['band'].indexOf(self.term) >= 0) || (entry['description'] && entry['description'].indexOf(self.term) >= 0);
    });

    return result;
  }

  setnewsletter(){
    this.submit = true;

    if (!this.newsletter  || !this.validateEmail(this.newsletter)){
      setTimeout(() => {
        this.submit = false;
      }, 1000);
      return;
    }

    this.dataService.saveItem("newsletter", {id: UUID.UUID(),  date: new Date(), email: this.newsletter}).then(data => {
      this.msg = "Trimis!";
      delete this.submit;

      setTimeout(() => {
        delete this.msg;
      }, 2000);
    })
  }

  onClickImg(id) {
    document.getElementById(id).click();
  }
  selectFile(event, id, index) {  
    this.selectedFiles = event.target.files;
    //this.preview(this.selectedFiles);
    this.compress(id, index, this.selectedFiles[0]);
  }
  compress(id, index, file) {
    let image: File = file;
    console.log(`Image size before compressed: ${image.size} bytes.`)

    this.selectedFiles = undefined;

    this.itemImage = { id: UUID.UUID(), name: file.name };

    let ext = 'jpg';
    if (file.name.split('.').length > 0)
      ext = file.name.split('.')[file.name.split('.').length - 1];
      
    this.currentFileUpload = new FileUpload(file);
    this.index = index;
    this.percentage = 10;

    // this.compressImage.compress(image)
    //   .pipe(take(1))
    //   .subscribe(compressedImage => {
    //     console.log(`Image size after compressed: ${compressedImage.size} bytes.`);
    //     this.upload(id, index, compressedImage)
    //     // now you can do upload the compressed image 
    //   })

    this.upload(id, index, image);
  }
  upload(id, index, image) {
  
      let self = this;
      this.index = index;

      if (!image)
      return;

      const file = image;

      this.startupload = true;
      
      this.selectedFiles = undefined;
      delete this.selectedFiles;    
      (<HTMLInputElement>document.getElementById(id)).value = '';

      this.itemImage = {id: UUID.UUID(), name: file.name};

      let ext = 'jpg';
      if (file.name.split('.').length > 0)
      ext = file.name.split('.')[file.name.split('.').length -1];      

      this.message = "waiting ..."
      this.currentFileUpload = new FileUpload(file);

      this.uploadService.pushFileToStorage(this.currentFileUpload, this.itemImage, 'images', ext).subscribe(
        async percentage => {
          this.percentage = Math.round(percentage && percentage > 10?percentage:10);
  
          if (this.percentage == 100) {

            let nr = 3000;
            while (!self.itemImage['file_url'] && nr >= 0) {
                await new Promise(resolve => setTimeout(() => resolve(true), 100));
                nr = nr - 10;
            }

            delete self.message;
            this.currentFileUpload = undefined;  

            this.item['image'] = self.itemImage['file_url'];  

            this.percentage = 0;
          }
        },
        error => {
          console.log(error);
        }
      );
    }

    clickfile(){
      if(document.getElementById('uploadControl1'))
      document.getElementById('uploadControl1').click()
    }
  validateEmail(email) {
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    return regex.test(email);
  }
}
