import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore'
import { map, switchMap, take, first, tap, catchError } from 'rxjs/operators'
import {of as observableOf, from as observableFrom, Observable, of} from 'rxjs';
import { UUID } from 'angular2-uuid';

//import { Utils } from 'src/app/shared/utils/utils'

@Injectable({
	providedIn: 'root',
})
export class DataService {
	currentUser: any
	path: string = ''
	term_;

	dataRef: AngularFirestoreCollection<any> = null;

	constructor(private angularFireAuth: AngularFireAuth, private afStore: AngularFirestore, public firestore: AngularFirestore) {
		this.angularFireAuth.authState.subscribe((user) => {
			if (user) {
				this.currentUser = user
			}
		})
	}

	getList(entity: string): Observable<any[]> {
		const ref = this.afStore.collection(entity)
		return ref.valueChanges();
	}

	getSubList(entity: string, id: string, subentity: string): Observable<any[]> {
		const ref = this.afStore.collection(`${entity}/${id}/${subentity}`)
		return ref.valueChanges();
	}

	getItemByID(entity: string, id: any): Observable<any> {
		const ref = this.afStore.collection(entity).doc(id)
		return ref.valueChanges();
	}

	getSubItemByID(entity: string, parentid: any, subentity: string, id: string): Observable<any> {
		const ref = this.afStore.collection(`${entity}/${parentid}/${subentity}`).doc(id)
		return ref.valueChanges();
	}

	saveItem(entity: string, item: any) {
		if (!item.id) item.id = UUID.UUID()

		const itemRef: AngularFirestoreDocument<any> = this.afStore.doc(`${entity}/${item.id}`)
		return itemRef.set(item, { merge: true })
	}
	saveSubItem(entity: string, id: string, subentity: string, item: any) {
		if (!item.id) item.id = UUID.UUID()

		const itemRef: AngularFirestoreDocument<any> = this.afStore.doc(`${entity}/${id}/${subentity}/${item.id}`)
		return itemRef.set(item, { merge: true })
	}

	saveItemById(entity: string, item: any, id: any) {
		if (id) {
			const itemRef: AngularFirestoreDocument<any> = this.afStore.doc(`${entity}/${id}`)
			return itemRef.set(item, { merge: true })
		}

		return of(null).toPromise()
	}

	async deleteItem(entity: string, uid: any) {
		// const itemRef: AngularFirestoreDocument<any> = this.afStore.doc(`${entity}/${uid}`);
		// await itemRef.delete();
		return this.afStore.collection(entity).doc(uid).delete()
	}
	async deleteSubItem(entity: string, id: string, subentity: string, subid: any) {
		return this.afStore.collection(`${entity}/${id}/${subentity}`).doc(subid).delete()
	}

	get timestamp() {
		return new Date()
	}



	//old
	getByID(table,id){
		this.dataRef = this.firestore.collection(table);
		let item =  this.dataRef.doc(id)  as AngularFirestoreDocument<any>;
		return observableFrom(item.valueChanges()).pipe(
		  tap((rs) => console.log('get item: ')));
	  }
	
	  create(table,item): any {
	
		let forS = {};
		this.dataRef = this.firestore.collection(table);
	
		if (!item.id) {
		  item.id = UUID.UUID();
	
		  if (item['createAt'])
		  forS['createAt'] = item['createAt'];
		  else
		  forS['createAt'] = new Date();
		  
		  forS['updatedAt'] = new Date();
		} else {
		  forS['updatedAt'] = new Date();
		}
	
		forS = {...forS, ...JSON.parse(JSON.stringify(item))};
		console.log('Saving ');
		
		return observableFrom(this.dataRef.doc(item.id).set(forS)).pipe(
		  tap((rs) => console.log('save item:')));
	  }
	  update(table,item){
		this.dataRef = this.firestore.collection(table);
		return observableFrom(this.dataRef.doc(item.id).update(item)).pipe(
		  tap((rs) => console.log('Update item: ')));
	  }
	  delete(table,id: string) {
		this.dataRef = this.firestore.collection(table);
		let item = this.dataRef.doc(id) as AngularFirestoreDocument<any>;
		return observableFrom(item.delete());
	  }
	
}
