import { Component, AfterViewInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'eFactura.biz';

  constructor(
    private elementRef:ElementRef) {

}

  ngAfterViewInit() {  
    this.addscript(this.elementRef.nativeElement, "assets/js/jquery.cookiebar.js", "jquerycookiebarjs");
    this.addscript(this.elementRef.nativeElement, "assets/js/loadcookie.js", "loadcookiejs");
  }

  addscript(_elem, _file, _class, _class_general = ''){

    let content = document.getElementsByTagName('body')[0];
    let arr = document.getElementsByClassName(_class);
    for (let i = 0; i < arr.length; i++) {
    arr[i].remove();
    i = i-1;
    }

    var s = document.createElement("script");
    s.type = "text/javascript"; //"text/javascript";

    s.classList.add(_class);
    if (_class_general != '')
    s.classList.add(_class_general);

    s.src = _file;
    _elem.appendChild(s);

    return s;
}
}
