import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "sort",
    pure: true
})
export class SortPipe  implements PipeTransform {
    transform(array: any[], field: string, type: string='ASC'): any[] {
        array.sort((a: any, b: any) => {
            if (type == 'ASC'){
                if (a[field] < b[field]) {
                    return -1;
                } else if (a[field] > b[field]) {
                    return 1;
                } else {
                    return 0;
                }
            }
            else{
                if (a[field] > b[field]) {
                    return -1;
                } else if (a[field] < b[field]) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
        return array;
    }
}